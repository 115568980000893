import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { flatCasinoGames } from "./games.utils";

export interface GamesState {
  isFetching?: boolean;
  isFetchingWidget?: boolean;
  casino?: GamesListResponsePayload;
  casinoFlat?: ProviderGame[];
  casinoLiveFlat?: ProviderGame[];
  casinoLive?: GamesListResponsePayload;
  virtual?: GamesListResponsePayload;
  highlights?: HighlightsResponsePayload;
  game?: GamesStartResponsePayload;
  iFrameState: boolean;
  casinoFilterState: string;
  casinoGamesSearchValue: string;
  isSearchMenuOpen: boolean;
  isProvidersMenuOpen: boolean;
}
export interface Provider {
  id?: number;
  name?: string;
  games?: ProviderGame[];
  icon: string;
}
export interface ProviderGame {
  category_id?: string;
  auto_start?: boolean;
  enabled?: boolean;
  icon?: string;
  id?: number;
  label?: string;
  special?: boolean;
}

export interface GamesStartResponsePayload {
  content: string;
  mode: string;
  name: string;
  size: string;
  type: string;
}

export interface GamesListResponsePayload {
  providers?: Provider[];
}
export interface HighlightsResponsePayload {
  banners?: ProviderGame[];
  specials?: ProviderGame[];
}
const initialState: GamesState = {
  isFetching: false,
  casino: undefined,
  casinoLive: undefined,
  casinoFlat: undefined,
  virtual: undefined,
  game: undefined,
  iFrameState: false,
  casinoFilterState: "allgames",
  casinoGamesSearchValue: "",
  isSearchMenuOpen: false,
  isProvidersMenuOpen: false,
};

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
    getHighlightsRequested: (state: GamesState) => {
      state.isFetching = true;
    },
    getHighlightsSucceded: (
      state: GamesState,
      action: PayloadAction<HighlightsResponsePayload>
    ) => {
      state.isFetching = false;
      state.highlights = action.payload;
    },
    getHighlightsFailed: (state: GamesState) => {
      state.isFetching = false;
    },
    getCasinoSucceded: (
      state: GamesState,
      action: PayloadAction<GamesListResponsePayload>
    ) => {
      state.casino = action.payload;
      state.casinoFlat = flatCasinoGames(action.payload);
      state.isFetching = false;
    },
    getCasinoRequested: (state: GamesState) => {
      state.isFetching = true;
    },

    getCasinoFailed: (state: GamesState) => {
      state.isFetching = false;
    },
    getCasinoLiveRequested: (state: GamesState) => {
      state.isFetching = true;
    },
    getCasinoLiveSucceded: (
      state: GamesState,
      action: PayloadAction<GamesListResponsePayload>
    ) => {
      state.casinoLive = action.payload;
      state.casinoLiveFlat = flatCasinoGames(action.payload);

      state.isFetching = false;
    },
    getCasinoLiveFailed: (state: GamesState) => {
      state.isFetching = false;
    },
    getVirtualRequested: (state: GamesState) => {
      state.isFetching = true;
    },
    getVirtualSucceded: (
      state: GamesState,
      action: PayloadAction<GamesListResponsePayload>
    ) => {
      state.virtual = action.payload;
      state.isFetching = false;
    },
    getVirtualFailed: (state: GamesState) => {
      state.isFetching = false;
    },
    getGameRequested: (state: GamesState, action: PayloadAction<number>) => {
      state.isFetching = true;
      action;
    },
    getGameSucceded: (
      state: GamesState,
      action: PayloadAction<GamesStartResponsePayload>
    ) => {
      state.game = action.payload;
      if (action.payload.mode !== "window") {
        state.iFrameState = true;
      }
      state.isFetching = false;
    },
    setIFrameState: (state: GamesState, action: PayloadAction<boolean>) => {
      state.iFrameState = action.payload;
    },
    clearIFrameState: (state: GamesState) => {
      state.iFrameState = false;
    },
    clearGameLink: (state: GamesState) => {
      state.game = undefined;
    },
    setCasinoFilterState: (
      state: GamesState,
      action: PayloadAction<string>
    ) => {
      state.casinoFilterState = action.payload;
    },
    saveGamesSearchValue: (
      state: GamesState,
      action: PayloadAction<string>
    ) => {
      state.casinoGamesSearchValue = action.payload;
    },
    setSearchMenu: (state: GamesState, action: PayloadAction<boolean>) => {
      state.isSearchMenuOpen = action.payload;
    },
    setProvidersMenu: (state: GamesState, action: PayloadAction<boolean>) => {
      state.isProvidersMenuOpen = action.payload;
    },
    clearHighlights: (state: GamesState) => {
      state.highlights = undefined;
    },
  },
});

export const {
  getHighlightsRequested,
  getHighlightsFailed,
  getHighlightsSucceded,
  getCasinoRequested,
  getCasinoSucceded,
  getCasinoFailed,
  getCasinoLiveRequested,
  getCasinoLiveSucceded,
  getCasinoLiveFailed,
  getVirtualRequested,
  getVirtualSucceded,
  getVirtualFailed,
  getGameRequested,
  getGameSucceded,
  clearGameLink,
  clearIFrameState,
  setIFrameState,
  setCasinoFilterState,
  saveGamesSearchValue,
  setSearchMenu,
  setProvidersMenu,
  clearHighlights,
} = gamesSlice.actions;

export default gamesSlice.reducer;
