import { LiveEvent, Palimpsest, MarketConfig } from "./liveSlice";
import { removeDuplicates, sortDataByOrderAttribute } from "services/utils";

interface IComposedLiveData {
  composedData: {
    sportsObj: Record<string, number[]>;
    categoriesObj: Record<string, number[]>;
    tournamentsObj: Record<string, Record<string, number>[]>;
  };
  orderedData: {
    orderedSports: Palimpsest[];
  };
}
export const composeLiveData = (
  events: Record<string, LiveEvent>,
  sports: Record<string, Palimpsest>,
  isVideoFilter: boolean
): IComposedLiveData => {
  let sportsObj: Record<string, number[]> = {};
  let categoriesObj: Record<string, number[]> = {};
  let tournamentsObj: Record<string, Record<string, number>[]> = {};
  for (const key in events) {
    if (isVideoFilter) {
      if (events[key].stream_id !== 0) {
        const eventSportId = events[key].sport_id;
        const eventCategoryId = events[key].category_id;
        sportsObj = {
          ...sportsObj,
          [eventSportId]: sportsObj[eventSportId]
            ? removeDuplicates([
                ...sportsObj[eventSportId],
                ...[eventCategoryId],
              ])
            : [eventCategoryId],
        };
        categoriesObj = {
          ...categoriesObj,
          [events[key].category_id]: categoriesObj[events[key].category_id]
            ? removeDuplicates([
                ...categoriesObj[events[key].category_id],
                ...[events[key].tournament_id],
              ])
            : [events[key].tournament_id],
        };
        tournamentsObj = {
          ...tournamentsObj,
          [events[key].tournament_id]: tournamentsObj[events[key].tournament_id]
            ? [
                ...tournamentsObj[events[key].tournament_id],
                ...[
                  {
                    id: events[key].id,
                    sportId: events[key].sport_id,
                    categoryId: events[key].category_id,
                    begin: events[key].begin,
                  },
                ],
              ]
            : [
                {
                  id: events[key].id,
                  sportId: events[key].sport_id,
                  categoryId: events[key].category_id,
                  begin: events[key].begin,
                },
              ],
        };
      }
    } else {
      const eventSportId = events[key].sport_id;
      const eventCategoryId = events[key].category_id;
      sportsObj = {
        ...sportsObj,
        [eventSportId]: sportsObj[eventSportId]
          ? removeDuplicates([...sportsObj[eventSportId], ...[eventCategoryId]])
          : [eventCategoryId],
      };
      categoriesObj = {
        ...categoriesObj,
        [events[key].category_id]: categoriesObj[events[key].category_id]
          ? removeDuplicates([
              ...categoriesObj[events[key].category_id],
              ...[events[key].tournament_id],
            ])
          : [events[key].tournament_id],
      };
      tournamentsObj = {
        ...tournamentsObj,
        [events[key].tournament_id]: tournamentsObj[events[key].tournament_id]
          ? [
              ...tournamentsObj[events[key].tournament_id],
              ...[
                {
                  id: events[key].id,
                  sportId: events[key].sport_id,
                  categoryId: events[key].category_id,
                  begin: events[key].begin,
                },
              ],
            ]
          : [
              {
                id: events[key].id,
                sportId: events[key].sport_id,
                categoryId: events[key].category_id,
                begin: events[key].begin,
              },
            ],
      };
    }
  }

  /**sort sports by order attribute */
  let orderedSportArray: Palimpsest[] = [];
  Object.keys(sportsObj).map((key) => {
    orderedSportArray = [...orderedSportArray, sports[key]];
  });

  return {
    composedData: {
      sportsObj: sportsObj,
      categoriesObj: categoriesObj,
      tournamentsObj: tournamentsObj,
    },
    orderedData: {
      orderedSports: sortDataByOrderAttribute(orderedSportArray),
    },
  };
};

export const orderCategories = (
  categories: Record<string, Palimpsest>,
  composedSports: Record<string, number[]>,
  selectedSport: number | undefined
): Palimpsest[] | undefined => {
  let orderedCategoryArray: Palimpsest[] = [];
  if (
    selectedSport &&
    composedSports &&
    composedSports[selectedSport] &&
    categories
  ) {
    composedSports[selectedSport].map((categoryId) => {
      orderedCategoryArray = [...orderedCategoryArray, categories[categoryId]];
    });
    return sortDataByOrderAttribute(orderedCategoryArray);
  }
};
export const orderTournaments = (
  categories: Record<string, Palimpsest>,
  orderedCategories: Palimpsest[],
  tournaments: Record<string, Palimpsest>,
  composedCategories: Record<string, number[]>,
  selectedCategory: string | number
): Palimpsest[] | undefined => {
  if (
    categories &&
    composedCategories &&
    selectedCategory === "all" &&
    tournaments
  ) {
    let allOrderedTournamentArray: Palimpsest[] = [];
    orderedCategories.map((category) => {
      composedCategories[category?.id]?.map((tournamentId) => {
        const composedTournament = {
          ...tournaments[tournamentId],
          categoryLabel: Object.values(categories).find(
            (category) => category.id === tournaments[tournamentId].category_id
          )?.label,
        };
        allOrderedTournamentArray = [
          ...allOrderedTournamentArray,
          composedTournament,
        ];
      });
    });
    return sortDataByOrderAttribute(allOrderedTournamentArray);
  } else if (
    composedCategories &&
    selectedCategory &&
    composedCategories[selectedCategory] &&
    tournaments
  ) {
    let orderedTournamentArray: Palimpsest[] = [];

    composedCategories[selectedCategory].map((tournamentId) => {
      orderedTournamentArray = [
        ...orderedTournamentArray,
        tournaments[tournamentId],
      ];
    });
    return sortDataByOrderAttribute(orderedTournamentArray);
  }
};

export const composeoverviewMarkets = (
  markets: Record<string, MarketConfig>,
  selectedSport: number | undefined
): MarketConfig[] => {
  let mainMarketsArray: MarketConfig[] = [];
  Object.values(markets).map((market) => {
    if (
      markets[market.id].sport_id === selectedSport &&
      markets[market.id].main
    ) {
      mainMarketsArray = [...mainMarketsArray, market];
    }
  });
  mainMarketsArray.sort((market1, market2) =>
    market1.order > market2.order ? 1 : market1.order < market2.order ? -1 : 0
  );
  return mainMarketsArray;
};

export const buildSearchData = (
  events: Record<string, LiveEvent>,
  sports: Record<string, Palimpsest>,
  tournaments: Record<string, Palimpsest>
): Record<string, LiveEvent[]> => {
  const searchDataObject: Record<string, LiveEvent[]> = {};
  Object.values(events).map((event: LiveEvent) => {
    const newEvent = {
      ...event,
      ...{
        tournamentLabel:
          tournaments[event.tournament_id] &&
          tournaments[event.tournament_id].label,
      },
    };
    if (searchDataObject[sports[event.sport_id].label]) {
      searchDataObject[sports[event.sport_id].label].push(newEvent);
    } else {
      searchDataObject[sports[event.sport_id].label] = [newEvent];
    }
  });
  return searchDataObject;
};

export const findSearchResults = (
  searchData: Record<string, LiveEvent[]>,
  searchValue: string
): Record<string, LiveEvent[]> => {
  const filtered = Object.keys(searchData).reduce(
    (acc: Record<string, LiveEvent[]>, key: string) => {
      const filteredChildren = searchData[key].filter((element) => {
        const label = `${element.home} - ${element.away}`;
        return (
          label?.toLowerCase().includes(searchValue.toLowerCase()) ||
          element.tournamentLabel
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
        );
      });
      if (filteredChildren.length > 0) {
        acc[key] = filteredChildren;
      }
      return acc;
    },
    {}
  );
  return filtered;
};
