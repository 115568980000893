import { instanceAxios } from "services/axiosServices";
import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  getConfigRequested,
  getConfigSucceded,
  getConfigFailed,
  getPalimpsestRequested,
  getPalimpsestSucceded,
  getPalimpsestFailed,
  getTournamentRequested,
  getTournamentSucceded,
  getTournamentFailed,
  updateTournament,
  getEventRequested,
  getEventSucceded,
  getEventFailed,
  getPrintOddRequested,
  getPrintOddSucceded,
  getPrintOddFailed,
  getSportWidgetRequested,
  getSportWidgetSucceded,
  getSportWidgetFailed,
  getFeaturedWidgetRequested,
  getFeaturedWidgetSuccess,
  getFeaturedWidgetFailed,
  updateSportWidget,
  getTopLeaguesWidgetTournamentRequested,
  getTopLeaguesWidgetTournamentSucceded,
  getTopLeaguesWidgetTournamentFailed,
} from "./sportSlice";

export function* getSportConfig(): unknown {
  try {
    const response = yield call(instanceAxios.get, "api/sport/config");
    const { data } = response.data;
    yield put(getConfigSucceded(JSON.stringify(data)));
  } catch (error) {
    yield put(getConfigFailed());
  }
}
export function* getPalimpsest(): unknown {
  try {
    const response = yield call(instanceAxios.get, "/api/sport/navbar");
    const { data } = response.data;
    yield put(getPalimpsestSucceded(JSON.stringify(data)));
  } catch (error) {
    yield put(getPalimpsestFailed());
  }
}

export function* getTournament({
  payload,
}: ReturnType<typeof getTournamentRequested>): unknown {
  try {
    const { action } = payload;
    const { tournamentId, groupId } = payload.data;
    const response = yield call(
      instanceAxios.get,
      `/api/sport/tournament/${tournamentId}/${groupId}`
    );
    const { data } = response.data;
    switch (action) {
      case "new":
        yield put(
          getTournamentSucceded(
            JSON.stringify({
              id: tournamentId,
              groupId: groupId,
              events: data.events,
              groups: data.marketgroups,
            })
          )
        );
        break;
      case "new-search":
        yield put(
          getTournamentSucceded(
            JSON.stringify({
              id: tournamentId,
              events: data.events,
              groups: data.marketgroups,
              groupId: groupId,
            })
          )
        );
        break;
      case "update-groups-events":
        yield put(
          updateTournament(
            JSON.stringify({
              id: tournamentId,
              data: {
                groups: data.marketgroups,
                events: data.events,
              },
            })
          )
        );
        break;
    }
  } catch (error) {
    yield put(getTournamentFailed());
  }
}
export function* getEvent({
  payload,
}: ReturnType<typeof getEventRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/sport/event/${payload}`
    );
    const { data } = response.data;
    yield put(getEventSucceded(data));
  } catch (error) {
    yield put(getEventFailed());
  }
}
export function* getWidgetTournament({
  payload,
}: ReturnType<typeof getTopLeaguesWidgetTournamentRequested>): unknown {
  try {
    const { action } = payload;
    const { tournamentId, groupId } = payload.data;
    const response = yield call(
      instanceAxios.get,
      `/api/sport/tournament/${tournamentId}/${groupId}`
    );
    const { data } = response.data;
    switch (action) {
      case "new":
        yield put(
          getTopLeaguesWidgetTournamentSucceded({
            id: tournamentId,
            groupId: groupId,
            events: data.events,
            groups: data.marketgroups,
          })
        );
        break;
      case "new-search":
        yield put(
          getTopLeaguesWidgetTournamentSucceded({
            id: tournamentId,
            events: data.events,
            groups: data.marketgroups,
            groupId: groupId,
          })
        );
        break;
      case "update-groups-events":
        yield put(
          updateTournament(
            JSON.stringify({
              id: tournamentId,
              data: {
                groups: data.marketgroups,
                events: data.events,
              },
            })
          )
        );
        break;
    }
  } catch (error) {
    yield put(getTopLeaguesWidgetTournamentFailed());
  }
}
export function* getSportWidget({
  payload,
}: ReturnType<typeof getSportWidgetRequested>): unknown {
  try {
    const { action } = payload;
    const { widget, sportId } = payload.data;
    const response = yield call(
      instanceAxios.get,
      `/api/sport/widget/${widget}/${sportId}`
    );
    const { data } = response.data;
    switch (action) {
      case "new":
        yield put(getSportWidgetSucceded(data));
        break;
      case "update-events":
        yield put(
          updateSportWidget({
            data: {
              events: data,
            },
          })
        );
        break;
    }
  } catch (error) {
    yield put(getSportWidgetFailed());
  }
}
export function* getPrintOdd({
  payload,
}: ReturnType<typeof getPrintOddRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/sport/printodd/?params=${JSON.stringify(payload)}`
    );
    const { data } = response.data;
    yield put(getPrintOddSucceded(data));
  } catch (error) {
    yield put(getPrintOddFailed());
  }
}
export function* getFeaturedData(): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      "/api/sport/widget/featured"
    );
    yield put(getFeaturedWidgetSuccess(response.data.data));
  } catch (err) {
    yield put(getFeaturedWidgetFailed());
  }
}

export function* ongetConfigRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getConfigRequested, getSportConfig);
}
export function* ongetPalimpsestRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getPalimpsestRequested, getPalimpsest);
}
export function* ongetTournamentRequested(): Generator<unknown, void, unknown> {
  yield takeEvery(getTournamentRequested, getTournament);
}
export function* ongetEventRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getEventRequested, getEvent);
}
export function* ongetSportWidgetRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getSportWidgetRequested, getSportWidget);
}

export function* ongetWidgetTournamentRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeEvery(getTopLeaguesWidgetTournamentRequested, getWidgetTournament);
}

export function* ongetPrintOddRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getPrintOddRequested, getPrintOdd);
}
export function* ongetFeaturedWidgetRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getFeaturedWidgetRequested, getFeaturedData);
}
export function* sportSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(ongetConfigRequested),
    call(ongetPalimpsestRequested),
    call(ongetTournamentRequested),
    call(ongetWidgetTournamentRequested),
    call(ongetEventRequested),
    call(ongetPrintOddRequested),
    call(ongetSportWidgetRequested),
    call(ongetFeaturedWidgetRequested),
  ]);
}
