import { instanceAxios } from "services/axiosServices";
import { all, call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  getWalletRequested,
  getWalletSucceded,
  getWalletFailed,
  getChildrenRequested,
  getChildrenSucceded,
  getChildrenFailed,
  createWalletRequested,
  createWalletSucceded,
  createWalletFailed,
  getWalletToEditRequested,
  getWalletToEditSucceded,
  getWalletToEditFailed,
  editWalletRequested,
  editWalletSucceded,
  editWalletFailed,
  getSkinTransactionsRequested,
  getSkinTransactionsSucceded,
  getSkinTransactionsFailed,
  getNetworkTransactionsRequested,
  getNetworkTransactionsSucceded,
  getNetworkTransactionsFailed,
  getReceiptRequested,
  getReceiptSucceded,
  getReceiptFailed,
  getAscendantsRequested,
  getAscendantsSucceded,
  getAscendantsFailed,
  moveFoundRequested,
  moveFoundSucceded,
  moveFoundFailed,
  getSkinBettingRequested,
  getSkinBettingSucceded,
  getSkinBettingFailed,
  getNetworkBettingRequested,
  getNetworkBettingSucceded,
  getNewtorkBettingFailed,
  getProvisionalPlanRequested,
  getProvisionalPlanSucceded,
  getProvisionalPlanFailed,
  getRevenuesRequested,
  getRevenuesSucceded,
  getRevenuesFailed,
  getFilteredSearchResultsRequested,
  getFilteredSearchResultsSucceded,
  getFilteredSearchResultsFailed,
  getRevenuesChildrenRequested,
  getRevenuesChildrenSucceded,
  getRevenuesChildrenFailed,
  getWalletBonusRequested,
  getWalletBonusFailed,
  getWalletBonusSucceded,
  getLoginHistoryRequested,
  getLoginHistorySucceded,
  getLoginHistoryFailed,
  moveWalletRequested,
  moveWalletSucceded,
  moveWalletFailed,
  getRevenueDetailsRequested,
  getRevenueDetailsSucceded,
  getRevenueDetailsFailed,
  getWalletImpersonateFailed,
  getWalletImpersonateRequested,
  getWalletImpersonateSucceded,
} from "./walletSlice";
import { openAlert } from "../alert/alertSlice";
import { updateWallet } from "../auth/authSlice";
import { openDialog } from "redux/dialog/dialogSlice";

export function* getWallet({
  payload,
}: ReturnType<typeof getWalletRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/get/${payload.id}`
    );
    switch (payload.action) {
      case "update":
        yield put(updateWallet(response.data.data));
        break;
      case "new":
        yield put(getWalletSucceded(response.data.data));
        break;
      case "new-update":
        yield put(getWalletSucceded(response.data.data));
        yield put(updateWallet(response.data.data));
        break;
    }
  } catch (error) {
    yield put(getWalletFailed());
  }
}
export function* getChildren({
  payload,
}: ReturnType<typeof getChildrenRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/children/${payload.id}`
    );
    yield put(
      getChildrenSucceded(
        JSON.stringify({
          id: payload.id,
          caller: payload.caller,
          type: payload.type,
          children: response.data.data,
        })
      )
    );
  } catch (error) {
    yield put(getChildrenFailed(payload.type));
  }
}
export function* getAscendants({
  payload,
}: ReturnType<typeof getAscendantsRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/ancestors/${payload}`
    );
    yield put(getAscendantsSucceded(response.data.data));
  } catch (error) {
    yield put(getAscendantsFailed());
  }
}
export function* createWallet({
  payload,
}: ReturnType<typeof createWalletRequested>): unknown {
  try {
    const { id, data } = payload;
    const response = yield call(
      instanceAxios.post,
      `/api/wallet/create/${id}`,
      data
    );
    yield put(
      createWalletSucceded({
        id: id,
        children: [response.data.data],
      })
    );
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
        isAutoHide: true,
      })
    );
  } catch (error) {
    yield put(createWalletFailed());
  }
}
export function* getWalletToEdit({
  payload,
}: ReturnType<typeof getWalletToEditRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/detail/${payload}`
    );
    yield put(
      getWalletToEditSucceded({
        id: payload,
        data: response.data.data,
      })
    );
  } catch (error) {
    yield put(getWalletToEditFailed());
  }
}
export function* getLoginHistory({
  payload,
}: ReturnType<typeof getLoginHistoryRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `api/wallet/loginhistory/${payload}`
    );
    if (response) {
      yield put(
        getLoginHistorySucceded({
          wallet_id: payload.id,
          data: response.data.data,
        })
      );
    }
  } catch (error) {
    yield put(getLoginHistoryFailed());
  }
}
export function* editWallet({
  payload,
}: ReturnType<typeof editWalletRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.put,
      `/api/wallet/edit/${payload.id}`,
      payload.data
    );
    yield put(
      editWalletSucceded({
        childId: payload.id,
        parentId: response.data.data.parent_id,
        data: response.data.data,
        index: payload.index,
      })
    );
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
        isAutoHide: true,
      })
    );
  } catch (error) {
    yield put(editWalletFailed());
  }
}
export function* getSkinTransactions({
  payload,
}: ReturnType<typeof getSkinTransactionsRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/transactions/${payload.id}`,
      { params: { params: payload.params } }
    );
    yield put(getSkinTransactionsSucceded(response.data.data));
  } catch (error) {
    yield put(getSkinTransactionsFailed());
  }
}
export function* getNetworkTransactions({
  payload,
}: ReturnType<typeof getNetworkTransactionsRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/transactions/${payload.id}`,
      { params: { params: payload.params } }
    );
    yield put(getNetworkTransactionsSucceded(response.data.data));
  } catch (error) {
    yield put(getNetworkTransactionsFailed());
  }
}
export function* getReceipt({
  payload,
}: ReturnType<typeof getReceiptRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `api/transaction/receipt/${payload}`
    );
    yield put(getReceiptSucceded(response.data.data));
  } catch (error) {
    yield put(getReceiptFailed());
  }
}

export function* moveFound({
  payload,
}: ReturnType<typeof moveFoundRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `api/wallet/movefound/${payload.walletId}`,
      payload.data
    );
    yield put(moveFoundSucceded(response.data.data));
    yield put(
      openAlert({
        message: response.data.message,
        success: response.data.success,
        severity: "success",
        isAutoHide: true,
      })
    );
  } catch (error) {
    yield put(moveFoundFailed());
  }
}

export function* getSearchResults({
  payload,
}: ReturnType<typeof getFilteredSearchResultsRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/search/${payload.id}`,
      { params: { params: payload.params } }
    );
    yield put(getFilteredSearchResultsSucceded(response.data.data));
  } catch (error) {
    yield put(getFilteredSearchResultsFailed());
  }
}

export function* getSkinBetting({
  payload,
}: ReturnType<typeof getSkinBettingRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/coupon/list/${payload.id}`,
      { params: { params: payload.params } }
    );
    yield put(getSkinBettingSucceded(response.data.data));
  } catch (error) {
    yield put(getSkinBettingFailed());
  }
}

export function* getNetworkBetting({
  payload,
}: ReturnType<typeof getSkinBettingRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/coupon/list/${payload.id}`,
      { params: { params: payload.params } }
    );
    yield put(getNetworkBettingSucceded(response.data.data));
  } catch (error) {
    yield put(getNewtorkBettingFailed());
  }
}

export function* getRevenues({
  payload,
}: ReturnType<typeof getRevenuesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/revenues/${payload.id}`,
      { params: { params: payload.params } }
    );
    yield put(getRevenuesSucceded(response.data.data[0]));
  } catch (error) {
    yield put(getRevenuesFailed());
  }
}

export function* getRevenuesChildren({
  payload,
}: ReturnType<typeof getRevenuesRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/revenues/${payload.id}`,
      { params: { params: payload.params } }
    );
    if (payload.id)
      yield put(
        getRevenuesChildrenSucceded({
          id: payload.id,
          children: response.data.data,
        })
      );
  } catch (error) {
    yield put(getRevenuesChildrenFailed());
  }
}

export function* getProvisionalPlan({
  payload,
}: ReturnType<typeof getProvisionalPlanRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/provisionalplan/${payload.id}`
    );
    yield put(getProvisionalPlanSucceded(response.data.data));
  } catch (error) {
    yield put(getProvisionalPlanFailed());
  }
}

export function* getWalletBonus({
  payload,
}: ReturnType<typeof getWalletBonusRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/campaign/${payload}`
    );
    yield put(getWalletBonusSucceded(response.data.data));
  } catch (err) {
    yield put(getWalletBonusFailed());
  }
}

export function* moveWallet({
  payload,
}: ReturnType<typeof moveWalletRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.put,
      `/api/wallet/move/${payload.wallet_id}`,
      payload.data
    );
    yield put(
      moveWalletSucceded({
        data: response.data.data,
        newParentId: payload.data.parent_id,
        movedWallet: payload.wallet_id,
        oldParentId: payload.oldParent_id,
      })
    );
  } catch (err) {
    yield put(moveWalletFailed());
  }
}

export function* getRevenueDetails({
  payload,
}: ReturnType<typeof getRevenueDetailsRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.get,
      `/api/wallet/revenue/detail/${payload.id}`,
      { params: { params: { filters: payload.params } } }
    );
    yield put(getRevenueDetailsSucceded(response.data.data));
    yield put(openDialog({ type: "revenueDetail", payload: payload.wallet }));
  } catch (err) {
    put(getRevenueDetailsFailed());
  }
}

export function* getWalletImpersonate({
  payload,
}: ReturnType<typeof getWalletImpersonateRequested>): unknown {
  try {
    const response = yield call(
      instanceAxios.post,
      `/api/wallet/impersonate/${payload}`
    );
    const data = response.data.data;
    yield put(getWalletImpersonateSucceded(data));
  } catch (err) {
    put(getWalletImpersonateFailed);
  }
}

export function* ongetWalletRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getWalletRequested, getWallet);
}
export function* ongetChildrenRequested(): Generator<unknown, void, unknown> {
  yield takeEvery(getChildrenRequested, getChildren);
}
export function* ongetAscendantsRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getAscendantsRequested, getAscendants);
}
export function* oncreateWalletRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(createWalletRequested, createWallet);
}
export function* ongetWalletToEditRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getWalletToEditRequested, getWalletToEdit);
}
export function* ongetLoginHistoryRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getLoginHistoryRequested, getLoginHistory);
}
export function* oneditWalletRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(editWalletRequested, editWallet);
}
export function* ongetSkinTransactionsRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getSkinTransactionsRequested, getSkinTransactions);
}
export function* ongetNetworkTransactionsRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getNetworkTransactionsRequested, getNetworkTransactions);
}

export function* ongetReceiptRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getReceiptRequested, getReceipt);
}
export function* onmoveFoundRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(moveFoundRequested, moveFound);
}

export function* ongetSkinBettingRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getSkinBettingRequested, getSkinBetting);
}

export function* ongetNetworkBettingRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getNetworkBettingRequested, getNetworkBetting);
}

export function* ongetProvisionalPlanRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getProvisionalPlanRequested, getProvisionalPlan);
}

export function* ongetRevenuesRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(getRevenuesRequested, getRevenues);
}

export function* ongetRevenuesChildrenRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getRevenuesChildrenRequested, getRevenuesChildren);
}

export function* ongetFilteredSearchResultsRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getFilteredSearchResultsRequested, getSearchResults);
}

export function* onGetWalletBonusRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getWalletBonusRequested, getWalletBonus);
}

export function* onMoveWalletRequested(): Generator<unknown, void, unknown> {
  yield takeLatest(moveWalletRequested, moveWallet);
}

export function* onRevenueDetailsRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getRevenueDetailsRequested, getRevenueDetails);
}

export function* onWalletImpersonateRequested(): Generator<
  unknown,
  void,
  unknown
> {
  yield takeLatest(getWalletImpersonateRequested, getWalletImpersonate);
}

export function* walletSagas(): Generator<unknown, void, unknown> {
  yield all([
    call(ongetWalletRequested),
    call(ongetChildrenRequested),
    call(ongetAscendantsRequested),
    call(oncreateWalletRequested),
    call(ongetWalletToEditRequested),
    call(ongetLoginHistoryRequested),
    call(oneditWalletRequested),
    call(ongetSkinTransactionsRequested),
    call(ongetNetworkTransactionsRequested),
    call(ongetReceiptRequested),
    call(onmoveFoundRequested),
    call(ongetSkinBettingRequested),
    call(ongetNetworkBettingRequested),
    call(ongetProvisionalPlanRequested),
    call(ongetRevenuesRequested),
    call(ongetFilteredSearchResultsRequested),
    call(ongetRevenuesChildrenRequested),
    call(onGetWalletBonusRequested),
    call(onMoveWalletRequested),
    call(onRevenueDetailsRequested),
    call(onWalletImpersonateRequested),
  ]);
}
