import React, { SetStateAction, useLayoutEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer/dist/index.legacy.esm.js";
import { store } from "./../redux/store";
import { setIFrameState, clearIFrameState } from "redux/games/gamesSlice";
import { Palimpsest } from "redux/live/liveSlice";
import {
  IPalimpsestItem,
  IEventData,
  IOddData,
  IMarketData,
} from "redux/sport/sportSlice";
import { logoutRequested } from "redux/auth/authSlice";
import { closeDialog } from "redux/dialog/dialogSlice";
import {
  format,
  startOfWeek,
  startOfMonth,
  previousSunday,
  addDays,
  addMonths,
  subWeeks,
  endOfWeek,
  subMonths,
} from "date-fns";
import { useAppSelector } from "redux/hooks";

import { Odds, from } from "./oddslib";
/**
 * custom Hook for idle detection
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */

export const useIdleTimeout = ({ onIdle, idleTime = 1, userAuth }: any) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const handleIdle = () => {
    if (userAuth) {
      setIdle(true);
      store.dispatch(logoutRequested());
      store.dispatch(closeDialog());
    }
  };
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle: idleTimeout / 2,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};

export const useWindowSize = (): number[] => {
  const [size, setSize] = useState([window.innerWidth]);
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth]);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
};

export const checkWalletPermissions = (
  permissions: string[],
  permissionsValue: string
): boolean => {
  const value = permissions?.some((item: string) => {
    return item === permissionsValue;
  });
  return Boolean(value);
};
export const isObjectEmpty = (obj: Record<string, any>): boolean => {
  return Object.keys(obj).length === 0;
};

export const convertTimestamp = (timestamp: number): string => {
  const date = new Date(timestamp * 1000);
  return format(date, "dd-MM-yy HH:mm");
};

export const convertDateToEurpoean = (date: string): string => {
  return new Date(date).toLocaleString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const handleScroll = (
  e: any,
  func: React.Dispatch<SetStateAction<any>>,
  initialItemsToLoad: number,
  itemsToLoad: number
) => {
  const bottom =
    e.target.scrollHeight - e.target.scrollTop - 500 < e.target.clientHeight;
  if (bottom) {
    func(initialItemsToLoad + itemsToLoad);
  }
};

export const gameView = (
  gameLink: string,
  size: string | undefined,
  type: string | undefined
): Window | null | undefined => {
  store.dispatch(clearIFrameState());

  if (type === "link") {
    const win = window.open(
      gameLink,
      "name",
      size === "full"
        ? "height=" + screen.height + ",width=" + screen.width
        : `width=${size?.split(",")[0]}, height=${size?.split(",")[1]},left=${
            (window.outerWidth - 1025) / 2
          },top=800`
    );
    return win;
  } else if (type === "html" && gameLink) {
    const winUrl = URL.createObjectURL(
      new Blob([gameLink], { type: "text/html" })
    );
    const win = window.open(
      winUrl,
      "name",
      size === "full"
        ? "height=" + screen.height + ",width=" + screen.width
        : `width=${size?.split(",")[0]}, height=${size?.split(",")[1]},left=${
            (window.outerWidth - 1025) / 2
          },top=800`
    );
    return win;
  }

  store.dispatch(setIFrameState(true));
};

/*sort  items by order attribute*/
export const sortDataByOrderAttribute = (data: Palimpsest[]): Palimpsest[] => {
  return data.sort((firstItem, secondItem) =>
    firstItem.order > secondItem.order
      ? 1
      : firstItem.order < secondItem.order
      ? -1
      : 0
  );
};
/*sort  items by order attribute and then by label*/
export const sortDataByOrderAndLabelAttribute = (
  data: Palimpsest[]
): Palimpsest[] => {
  return data.sort((firstItem, secondItem) =>
    firstItem.order > secondItem.order
      ? 1
      : firstItem.order < secondItem.order
      ? -1
      : 0 || firstItem.label.localeCompare(secondItem.label)
  );
};
/*sort  items by category label attribute, tournament order  and then by tournament label*/
export const sortDataByCategoryOrderLabelAttribute = (
  data: Palimpsest[]
): Palimpsest[] => {
  return data.sort((firstItem, secondItem) =>
    (firstItem.categoryLabel &&
      secondItem.categoryLabel &&
      firstItem.categoryLabel.localeCompare(secondItem.categoryLabel)) ||
    firstItem.order > secondItem.order
      ? 1
      : firstItem.order < secondItem.order
      ? -1
      : 0 || firstItem.label.localeCompare(secondItem.label)
  );
};
/*remove duplicates from array of numbers*/
export const removeDuplicates = (arr: number[]): number[] => {
  const uniqueArray = arr.filter((elem, index, self) => {
    return (
      index ===
      self.findIndex((t) => {
        return t === elem;
      })
    );
  });
  return uniqueArray;
};

///////recursive functions////////////
/**find item by id */
export const recursiveSearch = (
  tournamentId: number,
  data: any
): IPalimpsestItem | null => {
  for (let i = 0; i < data?.length; i++) {
    if (data[i].id === tournamentId) {
      return data[i];
    }
    if (data[i].children) {
      const result = recursiveSearch(tournamentId, data[i].children);
      if (result) {
        return result;
      }
    }
  }
  return null;
};
/**find item by id and type, because id is not unic */
export const recursiveSearchByType = (
  id: number,
  data: any,
  type: string,
  type2?: string
): IPalimpsestItem | null => {
  for (let i = 0; i < data?.length; i++) {
    if (
      data[i].id === id &&
      (data[i].type === type || data[i].type === type2)
    ) {
      return data[i];
    }
    if (data[i].children) {
      const result = recursiveSearchByType(id, data[i].children, type, type2);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

/**filter recursive data given a certain value */
export const copy = (item: IPalimpsestItem): IPalimpsestItem => {
  return Object.assign({}, item);
};

export const filterTreeData = (
  item: IPalimpsestItem,
  filterValue?: number
): boolean | number | undefined => {
  if (
    filterValue &&
    item.begin &&
    Math.round(new Date().getTime() / 1000) < item.begin &&
    item.begin < filterValue
  )
    return true;

  if (item.children) {
    return (item.children = item.children
      .map(copy)
      .filter((item) => filterTreeData(item, filterValue))).length;
  }
};

export const groupedEventsByDate = (
  events: IPalimpsestItem[]
): Record<string, IPalimpsestItem[]> => {
  const groupedEvents = events?.reduce((dataGroupedByDate: any, event: any) => {
    const fullTime = event && convertBeginToDate(event.begin);
    const date = fullTime && fullTime.split(" ")[0];
    if (!dataGroupedByDate[date]) {
      dataGroupedByDate[date] = [];
    }
    dataGroupedByDate[date].push(event);
    return dataGroupedByDate;
  }, {});
  return groupedEvents;
};
const returnFullMarketDetails = (event: any, markets: any) => {
  return (event?.markets || []).map((market: IMarketData) => {
    const configMarketFound =
      (markets || []).find((m: IMarketData) => m.id == market.id) || {};
    const marketOddsFull = (configMarketFound.odds || []).map(
      (odd: IOddData) => {
        const oddValueFound = (market.odds || []).find((o) => o.id == odd.id);
        return { ...odd, extra: oddValueFound };
      }
    ) as IOddData[];

    //returned merged market here
    return {
      ...market,
      ...configMarketFound,
      odds: marketOddsFull,
    };
  });
};

export const groupdDataByTournamentId = (
  data: IEventData[],
  sports: any,
  markets: any
): Record<string, any> => {
  const groupedData = data.reduce(
    (dataGroupedByTournamentId: any, event: any) => {
      const matchedTournament = recursiveSearchByType(
        event.tournament_id,
        sports,
        "tournament",
        "t"
      );
      const matchedEvent = matchedTournament?.children?.find(
        (el) => el.id === event.id
      ) as any;

      const matchedEventMarketsFull = returnFullMarketDetails(event, markets);

      const merged = {
        tournamentLabel:
          matchedTournament !== null ? matchedTournament.label : "",
        ...event,
        ...{ ...matchedEvent, markets: matchedEventMarketsFull },
      };

      if (
        matchedTournament !== null &&
        matchedTournament.label &&
        matchedEvent &&
        !dataGroupedByTournamentId[matchedTournament.label]
      ) {
        dataGroupedByTournamentId[matchedTournament.label] = [];
      }
      matchedTournament !== null &&
        matchedTournament.label &&
        matchedEvent &&
        dataGroupedByTournamentId[matchedTournament.label].push(merged);
      return dataGroupedByTournamentId;
    },
    {}
  );
  return groupedData;
};

export const groupdDataByDate = (
  events: any,
  sports: any,
  markets: any
): Record<string, any> => {
  const groupedData = events.reduce((dataGroupedByDate: any, event: any) => {
    const match = recursiveSearchByType(event.id, sports, "e");
    const matchedTournament = recursiveSearchByType(
      event.tournament_id,
      sports,
      "tournament",
      "t"
    );
    const matchedEventMarketsFull = returnFullMarketDetails(event, markets);
    const merged = {
      ...event,
      ...{ ...match, markets: matchedEventMarketsFull },
      tournamentLabel: matchedTournament?.label || "",
    };
    const fullTime = convertBeginToDate(merged.begin);
    const date = fullTime.split(" ")[0];
    if (match && !dataGroupedByDate[date]) {
      dataGroupedByDate[date] = [];
    }
    match && dataGroupedByDate[date].push(merged);
    const sortedData: any = {};
    const sortedKeys = Object.keys(dataGroupedByDate).sort();
    sortedKeys.map((key: any) => {
      if (sortedData[key]) {
        sortedData[key] = dataGroupedByDate[key];
      } else {
        sortedData[key] = [];
        sortedData[key] = dataGroupedByDate[key];
      }
    });
    return sortedData;
  }, {});
  return groupedData;
};

export const groupdDataByDateAndTournament = (
  events: IEventData[],
  sports: any,
  markets: any
): Record<string, any> => {
  const groupedData = events.reduce((dataGroupedByDate: any, event: any) => {
    const matchedTournament = recursiveSearchByType(
      event.tournament_id,
      sports,
      "tournament",
      "t"
    );
    const matchedEvent = matchedTournament?.children?.find(
      (el) => el.id === event.id
    );

    const matchedEventMarketsFull = returnFullMarketDetails(event, markets);
    const merged = {
      ...event,
      ...{ ...matchedEvent, markets: matchedEventMarketsFull },
      tournamentLabel: matchedTournament?.label || "",
    };
    const fullTime = convertBeginToDate(merged.begin);
    const date = fullTime.split(" ")[0];
    if (matchedTournament && matchedEvent && !dataGroupedByDate[date])
      dataGroupedByDate[date] = {};
    if (
      matchedTournament &&
      matchedEvent &&
      !dataGroupedByDate[date][merged.tournamentLabel]
    )
      dataGroupedByDate[date][merged.tournamentLabel] = [];
    matchedTournament &&
      matchedEvent &&
      dataGroupedByDate[date][merged.tournamentLabel].push(merged);
    return dataGroupedByDate;
  }, {});
  return groupedData;
};

/**month contabile date calculation */

export const lastSundayPPreviousMonth = (month: Date) => {
  const date = addDays(
    startOfWeek(subWeeks(startOfMonth(subMonths(new Date(), 1)), 1)),
    7
  );
  return date;
};
export const lastSundayPreviousMonth = (day: Date) => {
  const date = addDays(startOfWeek(subWeeks(startOfMonth(day), 1)), 7);

  return date;
};
export const lastSundayCurrentMonth = (month: Date) => {
  const date = addDays(
    startOfWeek(subWeeks(startOfMonth(addMonths(month, 1)), 1)),
    7
  );

  return date;
};

export interface SplitObject {
  [key: string]: IEventData[];
}
export interface SingleTournament {
  key: string;
  items: IEventData[];
}
export interface SplitContentDateTournament {
  [key: string]: SplitObject;
}
export interface SingleTournamentDateTournament {
  tournament: string;
  items: IEventData[];
}
export interface DateTournamentItem {
  key: string;
  items: SingleTournamentDateTournament[];
}

export const splitContentIntoPages = (
  content: SplitObject,
  orientation: string,
  pageHeight: number,
  sports: IPalimpsestItem[] | undefined,
  order: string
): SingleTournament[][] | undefined => {
  if (content) {
    const pages: SingleTournament[][] = [];
    let currentPageHeight = 0;
    let allData: SingleTournament[] = [];
    Object.keys(content).map((key) => {
      content[key].map((item: IEventData) => {
        const itemHeight =
          order === "tournament"
            ? orientation === "landscape"
              ? 32
              : 27.5
            : orientation === "landscape"
            ? 32
            : 27;
        if (
          currentPageHeight + itemHeight + 35 + allData.length * itemHeight >
          pageHeight
        ) {
          pages.push(allData);
          allData = [];
          currentPageHeight = 0;
        }
        const objectExists =
          order === "date"
            ? allData.find((obj: SingleTournament) => obj.key === key)
            : allData.find(
                (obj: SingleTournament) =>
                  obj.key ===
                  key +
                    " - " +
                    recursiveSearchByType(
                      item.category_id,
                      sports,
                      "category",
                      "c"
                    )?.label
              );
        if (objectExists) {
          objectExists.items?.push(item);
        } else {
          allData.push({
            key:
              order === "date"
                ? key
                : key +
                  " - " +
                  recursiveSearchByType(
                    item.category_id,
                    sports,
                    "category",
                    "c"
                  )?.label,
            items: [item],
          });
        }
        currentPageHeight += itemHeight;
      });
    });
    if (allData.length > 0) {
      pages.push(allData);
    }
    return pages;
  }
};

export const splitContentIntoPagesDateTournament = (
  content: SplitContentDateTournament,
  orientation: string,
  pageHeight: number,
  sports: IPalimpsestItem[] | undefined
): DateTournamentItem[][] | undefined => {
  const pages: DateTournamentItem[][] = [];
  let currentPageHeight = 0;
  let allData: DateTournamentItem[] = [];
  let tournamentsLength = 0;

  const sortedContent =
    content &&
    Object.fromEntries(
      Object.entries(content).sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
    );
  if (sortedContent) {
    Object.keys(sortedContent).map((contentEntry) => {
      Object.keys(sortedContent[contentEntry]).map((item: string) => {
        tournamentsLength =
          tournamentsLength + sortedContent[contentEntry][item].length;
        sortedContent[contentEntry][item].map((i: IEventData) => {
          const itemHeight = orientation === "landscape" ? 55 : 45;
          if (currentPageHeight + itemHeight + 35 > pageHeight) {
            pages.push(allData.sort((a: any, b: any) => a.key - b.key));
            allData = [];
            currentPageHeight = 0;
          }
          const objectExists = allData.find(
            (obj: DateTournamentItem) => obj.key === contentEntry
          );
          if (objectExists) {
            const itemsObjectExists = objectExists.items.find(
              (obj: SingleTournamentDateTournament) =>
                obj.tournament ===
                item +
                  " - " +
                  recursiveSearchByType(i.category_id, sports, "category", "c")
                    ?.label
            );
            if (itemsObjectExists) {
              itemsObjectExists.items.push(i);
            } else {
              objectExists.items.push({
                tournament:
                  item +
                  " - " +
                  recursiveSearchByType(i.category_id, sports, "category", "c")
                    ?.label,
                items: [i],
              });
            }
          } else {
            allData.push({
              key: contentEntry,
              items: [
                {
                  tournament:
                    item +
                    " - " +
                    recursiveSearchByType(
                      i.category_id,
                      sports,
                      "category",
                      "c"
                    )?.label,
                  items: [i],
                },
              ],
            });
          }
          currentPageHeight += itemHeight;
        });
      });
    });
    if (allData.length > 0) {
      pages.push(allData);
    }
    return pages;
  }
};

/************************************/
// export const getLastSunday = (year: any, month: any) => {
//   const d = new Date(year, month, 0);
//   d.setDate(d.getDate() - d.getDay());
//   return d;
// };
export const getLastSunday = (year: any, month: any) => {
  const d = new Date(year, month, 0);
  d.setDate(d.getDate() - d.getDay());
  // Set time to 24:59:59
  d.setHours(23, 59, 59, 0);
  return d;
};

export const convertBeginToDate = (begin: number | undefined) => {
  const timeStamp = begin && new Date(begin * 1000);
  const year = timeStamp && timeStamp.getFullYear();
  const month =
    timeStamp && (timeStamp.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const day = timeStamp && timeStamp.getDate().toString().padStart(2, "0");
  const hours = timeStamp && timeStamp.getHours().toString().padStart(2, "0");
  const minutes =
    timeStamp && timeStamp.getMinutes().toString().padStart(2, "0");
  const seconds =
    timeStamp && timeStamp.getSeconds().toString().padStart(2, "0");
  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
};

export const containsValue = (array: string[], value: string) => {
  // Loop through each string in the array
  for (let i = 0; i < array.length; i++) {
    // Split the string by '|' character
    const parts = array[i].split("|");
    // Check if any part matches the desired value
    for (let j = 0; j < parts.length; j++) {
      if (parts[j] === value) {
        // If found, return true
        return true;
      }
    }
  }
  // If not found, return false
  return false;
};

export const convertOddValue = (oddValue: number): string => {
  const oddType = store.getState().config.oddsType;
  let oddNewValue;

  switch (oddType.type) {
    case "american":
      oddNewValue = Math.ceil(
        Number(Odds.from("decimal", oddValue).to("moneyline"))
      ).toString();
      break;
    case "fraction":
      oddNewValue = Odds.from("decimal", oddValue)
        .to("fractional", { precision: 1 })
        .toString();
      break;
    default:
      oddNewValue = oddValue.toFixed(2).toString();
      break;
  }

  return oddNewValue;
};
